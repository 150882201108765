body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  margin: 0px;
  padding: 0px;
  height: 100vh;
  --purpleBg: #6240C9;
  --purpleBgLight: #F0EBFB;
  --purpleLight: #7a68b1;
  --blue: #1e90ff;
  --white: #ffffff;
  --textGrey: #B2B2B2;
  --purpleHeading: #F1EBFF;
}

/* forgot password text css */
.forgot_pass {
  float: right;
  margin-top: -34px;
  color: var(--purpleBg);
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.box {
  width: 50%
}

.button_common {
  background-color: var(--purpleBg);
  color: var(--white);
  height: 2.5rem;
  width: 16%;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: large;
  border-radius: 15px;
}

.button_common:hover {
  background-color: var(--purpleLight) !important;
  color: rgb(7, 7, 7) !important;
}

.formClass {
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 15px;
}

.dont_acc {
  text-align: center;
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
}

.font_size {
  font-size: 1.25rem;
}

@media screen and (max-width : 992px) {
  .formClass {
    width: 65%
  }
}

.ant-layout-header {
  background-color: var(--white) !important;
}

.ant-select-arrow {
  color: var(--purpleBg) !important;
}

.ant-select.ant-select-single.ant-select-show-arrow:hover {
  border-color: var(--purpleBg) !important;
}

.ant-layout-header {
  padding: 0 30px;
}

.user-profile {
  display: inline-flex;
  height: 50px;
  width: 8rem !important;
  border: 1px solid rgb(207, 205, 205);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width : 900px) {
  .user-profile {
    width: 15rem;
  }
}

.ant-dropdown-trigger .ant-dropdown-open {
  background-color: rgb(31, 207, 207) !important;
}

span.ant-menu-title-content>a {
  text-decoration: none !important;
}

.ant-menu-item-selected {
  background-color: var(--purpleBgLight) !important;
  color: var(--purpleBg) !important
}

.ant-radio .ant-wave-target .ant-radio-checked {
  background-color: var(--purpleBg) !important;
}

.notiText {
  color: var(--textGrey);
  font-size: 15px;
}

.ant-radio-wrapper:hover {
  border-color: var(--purpleBg) !important;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  background-color: var(--purpleHeading) !important;
  color: var(--purpleBg) !important
}

.viewButton {
  background-color: var(--purpleBg);
  color: var(--white);
  height: 2rem;
  /* width: 36%; */
  letter-spacing: 1px;
  font-weight: 500;
  border-radius: 15px;
}

.viewButton:hover {
  color: white !important;
  border-color: #6240C9 !important;
}

.ant-pagination .ant-pagination-item-active a {
  background-color: var(--purpleBg);
  border-color: none !important;
  border-radius: 3px !important;
  color: var(--white) !important
}

.purpleButton {
  background-color: var(--purpleBg) !important;
  color: white
}
.redButton {
  background-color: var(--purpleBg) !important;
  color: white
}

.purpleButton:hover {
  background-color: var(--purpleLight) !important;
  color: white !important;
  border: none;
}

.logout_dropdown {
  background-color: var(--purpleLight) !important;
}

.input_width {
  width: 50%;
}

.w-48 {
  width: 48% !important;
}

.ant-modal-title {
  text-align: center;
}

.w-10 {
  width: 9% !important;
}

.highcharts-area {
  fill: var(--purpleBg) !important;
}

path.highcharts-point.highcharts-color-0 {
  fill: var(--purpleBg) !important;
}

rect.highcharts-point {
  fill: var(--purpleBg) !important;
}

.ant-pagination-item-active {
  border: none !important;
}

.ant-table-row-selected {
  background-color: #6240C9 !important;
}

span.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
  display: none !important;
}

.ant-form-item-control-input-content>div {
  justify-content: space-between;
}

@media screen and (max-width : 425px) {
  .button_common {
    width: 64%;
  }

  .font_size {
    font-size: 1rem;
  }

  .input_width {
    width: 100%;
  }

  .box {
    width: 100%;
  }
}

@media screen and (min-width : 1800px) {
  .box {
    width: 30%
  }
}

@media screen and (min-width : 2200px) {
  .button_common {
    width: 10%;
  }

  .font_size {
    font-size: 2rem;
  }
}

.loader {
  position: fixed;
  z-index: 9999999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 14%;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  z-index: 10000;
}
.loaderModal {
  position: fixed;
  z-index: 9999999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  z-index: 5000;
}

.ant-spin .ant-spin-dot-item {
  background-color: #7966B2 !important;
}

.ql-editor {
  height: 300px !important;
}

/*  search boc border color */
span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg:focus,
span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg:hover,
span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg:active {
  border-color: #6240C9;
  box-shadow: none;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
  background-color: #6240C9 !important;
}

/* analytics tip page shyam-- */
.Maincard {
  box-shadow: 0px 5px 25px #6C3BD51A;
  border-radius: 10px;
}

.total_paidText {
  font-size: 0.9rem;
  font-weight: 600;
}

.dollerAmount {
  font-size: 1.5rem;
  font-weight: bold;
}

/* graphs */
.graphMainDiv {
  box-shadow: 5px 0px 15px #6C3BD50D;
  opacity: 1;
  background-color: #ffffff;
  border-radius: 10px;
}

.amountBox {
  /* width: 15%; */
  border-radius: 10px;
  background-color: #F8F5FF;
}

.ant-select-selector {
  border: 1px solid #a58be0 !important;
}

.unactive {
  cursor: pointer;
  color: #bdadf0;
}

.active {
  color: #6240C9;
  font-size: 1rem;
  font-weight: 600;
}

.ant-picker-time-panel {
  display: none !important;
}

.outBoxSettingInput {
  box-shadow: -1px 2px 14px -6px #d4c7f1 !important;
  border-radius: 8px;
  height: 65px;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settingInput input {
  border: none !important;
  background: transparent !important;
  font-size: 1rem !important;
}

.settingInput .ant-input-affix-wrapper {
  box-shadow: -1px 2px 14px -6px #d4c7f1 !important;
  border: none !important;
  background: transparent !important;
  height: 48px !important;
  width: 65% !important;
}

.subscrption .ant-input-affix-wrapper {
  /* width: 66% !important; */

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.settingUpdateBtn {
  width: 19%;
  height: 43px;
  border-radius: 10px;
}

.video-container {
  position: relative;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.advertiseMainDiv {
  background-color: #f0ebfb;
  border-radius: 10px;
}

.ant-switch.ant-switch-checked {
  background: #845cda !important
}

.ant-switch .ant-switch-handle::before {
  /* border: 2px solid #6240C9; */
}

.adsInputDiv {
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adsInputDiv .ant-input-affix-wrapper {
  border: 1px solid #8151e9 !important;
  background-color: white !important;
  height: 45px !important;
  width: 45% !important;
}
.rewrdInput .ant-input-affix-wrapper {
  border: 1px solid #8151e9 !important;
  height: 40px !important;
}

.ant-card-cover{
  display: flex;
  justify-content: center;
}
.filterMainDiv{
  height: 120px;
  border-radius:9px;
  background-color: #F1EBFF;
}
.topUsers{
  border-radius: 8px;
}

.green_dot{
  width: 10px;
  background-color: green;
  height: 10px;
  border-radius: 50%;
}

span.ant-collapse-header-text{
  font-size: large;
}

.ant-btn >.anticon+span{
  margin-inline-start:0px !important;
}
.back{
  background-color: var(--purpleBg) !important;
  color: white;
  font-size: large;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-btn{
  height: auto ;
}
.ant-modal-content{
  left : 24%;
}